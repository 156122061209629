.top_bar {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.top_bar2 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 19px;
}

.table_icon svg {
  height: 30px !important;
  /* width: 30px !important;
  margin: 10px;
  margin-top: 15px; */
}
