#components-layout-demo-custom-trigger .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
    color:pink;
}

#components-layout-demo-custom-trigger .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
}

.logo_img {
    height: 70px;
    margin: auto;
    width: 220px;
    padding-top: 20px;
}

.logo_img_small {
    height: 50px;
    margin: auto;
    margin-top: 10px;
}

.logo {
    display: flex;
}

.auth_wrapper {
    height: 100vh;
    display: flex;
    align-items: center;
    background: linear-gradient(45deg, #1A1A1A, #111111, #282828);
}

ul.ant-menu.ant-menu-dark.ant-menu-inline.ant-menu-root.css-dev-only-do-not-override-htwhyh.side_menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 22px;
    background-color: #111111 !important;
}

span.ant-menu-title-content {
    font-size: 14px;
}

.side_menu svg {
    font-size: 20px;
}
.ant-menu-dark .ant-menu-item-selected{
    background-color: #ECAC35 !important;
    color: #111111;
    font-weight: 500;
    
}
.ant-layout .ant-layout-sider-children{
    background-color: #111111;
}

/* .side_menu .ant-menu-item-selected {
    background-color: #019B69;
} */

.side_menu .ant-menu-item-active {
    background-color: #ecac35 !important;
}
.ant-menu-dark {
    background-color: #111111 !important;  
}


