.login_card {
    border: 1px solid black;
    width: 'fit-content';
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:#282828;
}

.login_img {
    display: flex;
    margin: 20px 0px;
}

.login_img img {
    height: 100px;
    margin: auto;
}