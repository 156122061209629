.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ant-layout {
  height: 100%;
  min-height: 100vh;
}

aside.ant-layout-sider.ant-layout-sider-dark {
  flex: 0 0 280px !important;
  max-width: 270px !important;
  min-width: 270px !important;
  width: 280px !important;
}

.coin_stat_card {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 50px 0px;
}

.coin_stat_card .card_icon {
  font-size: 40px;
  position: absolute;
  bottom: 80px;
  left: 0px;
  right: 0px;
}

.coin_stat_card .amount {
  font-size: 24px;
  font-weight: 500;
}

.stat_card {
  height: fit-content;
  width: 24%;
  text-align: center;
}
.stat_card2 {
  height: fit-content;
  width: 49.3%;
  display: flex;
  flex-direction: row;
  /* text-align: center; */
}

.stat_card2 .card_heading1 {
  font-size: 18px;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  

}

.stat_card2 .card_heading2 {
  font-size: 18px;
  font-weight: 500;
 


  

}

.stat_card2 .card_icon {
  /* display: flex; */
  /* align-items: center; */
  justify-content: space-around;
  width: 100%;
  font-size: 60px;
  display: flex;
  flex-direction: row;
  margin-right: 50px;

}

.stat_card .card_heading1 {
  font-size: 18px;
  font-weight: 700;

}

.stat_card .card_heading2 {
  font-size: 18px;
  font-weight: 500;
  

}

.stat_card .card_icon {
  /* display: flex; */
  align-items: center;
  justify-content: space-around;
  width: 100%;
  font-size: 60px;
}

.breadcrumb_item {
  cursor: pointer;
  padding: 2px 5px;
  border-radius: 10px;
  font-size: 12px;
}

.breadcrumb_item:hover {
  background: rgb(215, 211, 211);
  scale: 1.02;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.phn_cs input {
  height: 55px;
}

h4 {
  color: #011529;
}

h5 {
  font-size: 14px;
  font-weight: 400;
  color: grey;
}

.ant-select-selector {
  height: 55px !important;
  display: flex;
  align-items: center;
}

.ant-select-arrow {
  top: 40% !important;
}

.react-tel-input .selected-flag:focus:before,
.react-tel-input .selected-flag.open:before {
  border-color: #f7f4f4 !important;
  box-shadow: none !important;
}

.react-tel-input .country-list {
  outline: none;
  z-index: 1;
  list-style: none;
  position: absolute;
  padding: 0px 20px 20px 20px !important;
  margin: 10px 0 10px -1px;
  box-shadow: 1px 2px 18px rgba(0, 0, 0, 0.25);
  background-color: white;
  width: 400px !important;
  max-height: 220px !important;
  overflow-y: scroll;
  border-radius: 0px !important;
  height: 600px !important;
  max-height: 250px !important;

  @media (max-width: 1280px) {
    width: 300px !important;
  }

  @media (max-width: 992px) {
    width: 230px !important;
  }
}

.react-tel-input .form-control {
  font-size: 16px;
  /* background: #f7f4f4 !important; */
  border: 1px solid black;
  /* border-radius: 5px; */
  width: 100% !important;
  outline: none;
  padding: 18.5px 14px 18.5px 60px;
  transition: box-shadow ease 0.25s, border-color ease 0.25s;
  /* color: #495057; */
  /* border: none !important; */
  border-radius: 10px !important;
  margin-bottom: 10px !important;
  display: flex !important;
  height: 55px !important;
  /* &:focus {
    border-color: "red" !important;
    box-shadow: none !important;
  } */
}

.react-tel-input .country-list .search {
  z-index: 2;
  position: sticky;
  top: 0;
  background-color: red;
  padding: 10px 0 6px 10px;
  width: 100%;
}

.react-tel-input .country-list .search-box {
  /* border: 1px solid $primaryClr !important; */
  border-radius: 10px;
  font-size: 15px;
  line-height: 15px;
  margin-left: 6px;
  padding: 7px 11px 9px !important;
  outline: none;
  width: 94%;
  box-sizing: border-box;
  /* &::placeholder {
    text-transform: capitalize;
  } */
}

.srch {
  display: grid;
  gap: 25px;
}

.table_icon svg {
  margin-top: 8px;
}

span.coin_name {
  margin-left: 8px;
  color: grey;
  font-weight: 500;
}

span.coin_nme {
  padding-left: 20px;
  align-self: center;
}

.ant-checkbox .ant-checkbox-inner {
  border-color: grey; /* Change the border color of the checked checkbox */
}

.ant-layout-sider-children {
  overflow-y: scroll;
  /* padding-left: 20px; */
  width: 270px;
  
}
::-webkit-scrollbar {
  width: 3px;
  display: none;
}

.tableSelector ::-webkit-scrollbar {
  width: 3px !important;
  display: block !important;
  overflow-y: scroll;
  overflow-x: hidden !important;
  /* width: 3px ; */
}
/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(227, 226, 225);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(188, 187, 187);
}

.buton {
  margin: 10px ;
  width: 140px;
  border-width: 1px;
  border-color: #192e81;
}
.buton:hover {
  background-color: #ECAC35; 
  color:#fff !important
}
.tableCol{
  font-size: 12px;
  
}


.chart_card .ant-select-selector {
  width: 100%;
  height: 40px !important;
  padding: 0 11px;
  display: flex;
  align-items: center;
  outline: none;
}

.ant-select-arrow {
  top: 50% !important;
}


.content_header {
  
    margin-bottom: 20px !important;
   
}

.content_header>div {
  /* margin: 20px 0px !important; */
    /* background-color: pink !important; */
    padding:20px 20px !important;
    border-radius: 8px !important;
    /* height: 75px; */
   
}


.cdddddd .ant-select-arrow{
  position: absolute;
  top: 40% !important
}

.select_type{
  height: 43px !important;
}

.select_type > div{
  height: 100% !important;
}
.tableSelector .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  display: none;
}

.custom-number-input::-webkit-inner-spin-button,
.custom-number-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-number-input {
  -moz-appearance: textfield; /* Firefox */
}
.graph_ch canvas{
  width: 100% !important;
}

.smaller-title {
  font-size: 12px; /* Adjust the font size as per your preference */
}
:where(.css-htwhyh).ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 40px !important;
}
/* :where(.css-htwhyh).ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input */

/* countryCode.includes("+") ? countryCode : "+" + countryCiode */